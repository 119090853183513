import { toRaw }        from "vue";
import { Options, Vue } from "vue-class-component";

import { FilterMatchMode }  from "primevue/api";
import { VueDraggableNext } from 'vue-draggable-next'

import { Zone }       from "@/model/Zone";
import { OrderOnMap } from "@/model/OrderOnMap";
import { Taker }      from "@/model/Taker";
import { TakerOnMap } from "@/model/TakerOnMap";

import { zonesService }  from "@services/zones.service";
import { ordersService } from "@services/orders.service";

import { mapStyles, nearestPlace, turfDistances } from "@/utils/gmap_utils";

import { OrderStep, PlanningParams, planningService } from "@services/plannig.service";

import moment                 from "moment";
import { Prop }               from "vue-property-decorator";
import { takersService }      from "@services/takers.service";
import { PlanningRoutesEnum } from "../../router";

import { OrderPriorityTimes, ShiftToText } from "@/components"
import { OrderStatusEnum }                 from "@/model/enums/OrderStatusEnum";

const enum MarkerColor {
  GREEN = "#6BD0BA",
  RED   = "#EC816F",
  BLACK = "#000000",
}

function sameOrder( orderId: number, orderType: string ) {
  return function ( step: OrderStep ) {
    const sameOrderId = step.order_id === orderId;
    const sameStep    = step.step     === (orderType === 'pickup' ? 1 : 2);
    return sameOrderId && sameStep;
  }
}

function sortByPosition(plan: OrderStep[]) {
  return function(px: TrackPoint, py: TrackPoint) {
    const [xStep, yStep] = [px, py].map(om =>
      plan.find(sameOrder(om.orderOnMap.orderId, om.type))
    );

    if (!xStep) return 1;
    if (!yStep) return -1;

    return xStep.position - yStep.position;
  }
}

function sortByIndex(plan: OrderStep[]) {
  return function(px: TrackPoint, py: TrackPoint) {
    const [xIdx, yIdx] = [px, py].map(om =>
      plan.findIndex(sameOrder(om.orderOnMap.orderId, om.type))
    );

    if (xIdx === -1) return 1;
    if (yIdx === -1) return -1;

    return xIdx - yIdx;
  }
}



@Options( {
  name      : 'PlanningTakerPage',
  components: {
    draggable: VueDraggableNext,
    OrderPriorityTimes,
    ShiftToText
  },
  beforeRouteLeave( to, from, next ) {
    if (this.isDirty) {
      this.$confirmMessage(
          "Ci sono modifiche non salvate. Sicuro di voler continuare?",
          "Attenzione"
      ).then( next )
    } else {
      next();
    }
  }
})
export default class PlanningTakerPage extends Vue {
  isDirty: boolean = false;

  readonly backRoute = { name: PlanningRoutesEnum.PLANNING_TRACK_FILTERS };

  @Prop() readonly date: Date;
  @Prop() readonly takerId: number;
  @Prop() readonly zoneId!: number;
  @Prop() readonly shiftId: number;
  @Prop() readonly readonly: boolean;

  zone: Zone              = null;
  taker      : Taker      = null;
  takerOnMap : TakerOnMap = null;

  get shift() {
    return this.$store.getters.shiftById(+this.shiftId);
  }

  get paramsForRequest(): PlanningParams {
    return {
      date      : moment(this.date).format('yyyy-MM-DD'),
      entity_id : this.taker.id,
      shift_id  : this.shiftId || null,
      zone_id   : this.zone?.id,
      user_id   : this.taker.users[0].id
    }
  }


  directions   : google.maps.DirectionsResult   = null;
  renderer     : google.maps.DirectionsRenderer = null;
  orders       : OrderOnMap[]                   = null;
  trackPoints  : TrackPoint[]                   = null;
  existingPlan :  OrderStep[]                   = null;
  markerZindex  = 0;
  onSave() {
    this.savePlan();
  }

  isTaskCompleted(tp: TrackPoint) {
    if (tp.type === 'pickup') {
      return [
        40,50,200
      ].includes(tp.orderOnMap.order.status);
    } else {
      return [
        200
      ].includes(tp.orderOnMap.order.status);
    }
  }

  isTaskCanceled(tp: TrackPoint) {
    return [
      OrderStatusEnum.ANNULLATO,
      OrderStatusEnum.RIFIUTATO
    ].includes(tp.orderOnMap.order.status);
  }

  async onPreviewClick() {
    const hasErrors = this.orders
      .map( o => o.orderId)
      .some( id => !this.checkPosition(id));

    if (hasErrors) {
      this.$errorMessage(
        "Ci sono alcune consegne poste prima del ritiro."
      );
      return;
    }

    this.resetTrack();

    await this.calcDirections();

    this.calcDurationAndDistance();
    this.displayResults();

    await this.renderDirections();
  }

  renderDirections() {
    this.renderer = new google.maps.DirectionsRenderer({
      directions: this.directions,
      markerOptions: {
        visible : false,
      }
    });

    this.renderer.setMap(this.map);
  }

  async calcDirections() {
    const ds = new google.maps.DirectionsService();

    let start = {
      lat: this.takerOnMap.taker.start_lat,
      lon: this.takerOnMap.taker.start_lon,
    }

    if (this.takerOnMap.taker.last_order) {
      const o = this.takerOnMap.taker.last_order;
      start = {
        lat: o.dropoff_lat,
        lon: o.dropoff_lon,
      }
    }

    // Start del taker = Punto di partenza
    const origin = new google.maps.LatLng(
      start.lat, start.lon
    );

    // Ultimo punto della lista = Destinazione
    const destPoint = this.trackPoints.last();
    const destination = destPoint.type === 'pickup'
      ? destPoint.orderOnMap.pickupMarker.getPosition()
      : destPoint.orderOnMap.dropoffMarker.getPosition();

    let prev_point = null
    // Tutti gli altri punti = Fermate intermedie
    const waypoints: google.maps.DirectionsWaypoint[] = this.trackPoints
      .slice(0,this.trackPoints.length-1)
      .filter(t=>{
        let location;
        if ( t.type === 'pickup') {
          location = t.orderOnMap.pickupMarker.getPosition();
        } else {
          location = t.orderOnMap.dropoffMarker.getPosition();
        }

        const current_point = location.toString()
        if(prev_point == null || prev_point != current_point){
          prev_point = location.toString()
          return t;
        }

      })
      .map(t => {
        let location;

        if ( t.type === 'pickup') {
          location = t.orderOnMap.pickupMarker.getPosition();
        } else {
          location = t.orderOnMap.dropoffMarker.getPosition();
        }

        return { location } ;

      });

    this.directions = await ds.route({
      origin,
      destination,
      waypoints,
      travelMode: google.maps.TravelMode.DRIVING,
    });
  }

  calcDurationAndDistance() {
    const track   = this.directions.routes[0];

    this.routeDetails = track.legs.reduce( (a, x) => {
      a.distance += x.distance.value;
      a.duration += (x.duration_in_traffic?.value || x.duration.value);

      return a;
    }, {distance: 0, duration: 0})

    console.debug("Duration and distance", this.routeDetails);
  }

  resetTrack() {
    if (this.directions || this.renderer) {
      this.directions = null;
      this.renderer.setMap(null);
      this.renderer = null;
    }
    this.showRouteDetails = false;
  }

  //#region Gestione mappa
  mapRef: google.maps.Map = null;

  get map() {
    return toRaw(this.mapRef);
  }

  get mapElement() {
    return (this.$refs.mapEl as HTMLElement)
  }

  drawTakerOnMap() {
    this.takerOnMap?.setMap(this.map);
    this.takerOnMap.show();
  }

  toMapIcon(label: string, zoom = 1, color = MarkerColor.RED){
    return {
      url: `data:image/svg+xml,`+encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 27 43"><defs><path id="a" d="M12.5 0C5.5961 0 0 5.5961 0 12.5c0 1.8859.54297 3.7461 1.4414 5.4617 3.425 6.6156 10.216 13.566 10.216 22.195 0 .46562.37734.84297.84297.84297s.84297-.37734.84297-.84297c0-8.6289 6.7906-15.58 10.216-22.195.89844-1.7156 1.4414-3.5758 1.4414-5.4617 0-6.9039-5.5961-12.5-12.5-12.5z"/></defs><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)"><use fill="${color}" xlink:href="#a"/><path d="M12.5-.5c7.18 0 13 5.82 13 13 0 1.8995-.52398 3.8328-1.4974 5.6916-.91575 1.7688-1.0177 1.9307-4.169 6.7789-4.2579 6.5508-5.9907 10.447-5.9907 15.187 0 .74177-.6012 1.343-1.343 1.343s-1.343-.6012-1.343-1.343c0-4.7396-1.7327-8.6358-5.9907-15.187-3.1512-4.8482-3.2532-5.01-4.1679-6.7768-.97449-1.8608-1.4985-3.7942-1.4985-5.6937 0-7.18 5.82-13 13-13z" stroke="#fff"/></g><text text-anchor="middle" dy=".3em" x="14" y="15" font-family="Roboto, Arial, sans-serif" font-size="16px" fill="#FFF">${label}</text></g></svg>`),
      scaledSize : new google.maps.Size(27*zoom,43*zoom)
    };
  }

  drawOrdersOnMap() {
    if (!this.trackPoints?.length) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();

    this.trackPoints.forEach(tp => {
      const marker = tp.type == "dropoff" ? tp.orderOnMap.dropoffMarker : tp.orderOnMap.pickupMarker;
      marker.setIcon(
        this.toMapIcon(
          tp.ordinalNumber.toString(),
          1,
          tp.type === "dropoff" ? MarkerColor.GREEN : MarkerColor.RED
        )
      );
      marker.setMap(this.map);
      bounds.extend(marker.getPosition());

    });
    this.map.fitBounds(bounds);
  }
  onEndSortTp(){
    this.isDirty = true;
    this.resortOrdinalNumber()
  }

  resortOrdinalNumber(){
    let idx = 0;
    this.trackPoints.forEach(tp => {
      tp.ordinalNumber = ++idx;

      const [marker, color] = tp.type == "dropoff"
        ? [tp.orderOnMap.dropoffMarker, MarkerColor.GREEN]
        : [tp.orderOnMap.pickupMarker, MarkerColor.RED]

      marker.setIcon(
        this.toMapIcon(tp.ordinalNumber.toString(),1, color),
      );

      marker.setMap(this.map);
    });
    this.resetTrack();
  }

  onTpClick(tp: TrackPoint){
      const marker = tp.type == "dropoff" ? tp.orderOnMap.dropoffMarker : tp.orderOnMap.pickupMarker;
       marker.setIcon(
        this.toMapIcon(tp.ordinalNumber.toString(), 2, MarkerColor.BLACK)
      );
       marker.setZIndex(++this.markerZindex)
       //tp.refs.forEach(om => { (tp.type == "dropoff" ? om.dropoffMarker : om.pickupMarker).setZIndex(0)});
       setTimeout(()=>{
        marker.setIcon(
          this.toMapIcon(
            tp.ordinalNumber.toString(),
            1,
              tp.type === "dropoff" ? MarkerColor.GREEN : MarkerColor.RED
          )
        );
       },2000)
  }

  private _buildMap(element: HTMLElement) {

    const bounds = {
      north: this.zone.bounds.maxLat,
      south: this.zone.bounds.minLat,

      west: this.zone.bounds.minLon,
      east: this.zone.bounds.maxLon,
    }

    this.mapRef = new google.maps.Map(
      element,
      {
        center            : this.$config.startCenter,
        zoom              : this.$config.startZoom,
        disableDefaultUI  : true,
        fullscreenControl : true,
        zoomControl       : true,
        restriction       : {
          latLngBounds : bounds,
          strictBounds : false,
        },
        styles : mapStyles
      },
    );

    this.map.data.setStyle({
      strokeWeight  : 1,
      strokeOpacity : 1,
      strokeColor   : "#3399FF",
      fillColor     : "#3399FF",
      fillOpacity   : 0.2,
      editable      : false,
      draggable     : false,
      clickable     : true,
      zIndex        : 1,
    });
  }
  //#endregion

  private async _loadExistingPlan() {
    this.existingPlan =
      (await planningService.index(this.paramsForRequest)).data;
  }

  isLoadingOrders: boolean = false;
  private async _loadOrders() {

    try {
      this.isLoadingOrders = true;

      const filters = {
        entity_id: { // Filtro per il taker selezionato
          value: this.taker?.id,
           matchMode: FilterMatchMode.EQUALS
        },
        zone_id: { // Filtro per la zona selezionata
          value: this.zone?.id,
          matchMode: FilterMatchMode.EQUALS
        },
        order_date: { // Filtro per il giorno selezionato
          value: moment(this.date).format("yyyy-MM-DD"),
          matchMode: FilterMatchMode.EQUALS
        },
        shift_id: { // Filtro per la fascia oraria selezionata
          value: this.shiftId,
          matchMode: FilterMatchMode.EQUALS
        }
      }

      this.orders = (await ordersService.index({ filters, per_page: -1 }))
        .data.map(o => (new OrderOnMap(o)));

      if (this.orders.length) {
        await this.organize(this.orders);
      }
    } catch (error) {
      console.error(error)
      this.$errorMessage("Caricamento ordini non riuscito");
    } finally {
      this.isLoadingOrders = false;
    }
  }

  private async organize(orders: OrderOnMap[]) {
    const container = [...orders];

    const pickFirstPoint = () => {
      const firstPosition = container.splice(0,1)[0].pickupMarker.getPosition();
      return [firstPosition.lat(), firstPosition.lng()]
    }

    const { start_lat, start_lon } = this.taker;

    let position: number[] = (start_lat && start_lon)
      ? [start_lat, start_lon]
      : pickFirstPoint();

    // Punti di prelievo
    const destinations = () => container.map( ({order}) => {
      return [ order.pickup_lat, order.pickup_lon];
    });


    const points: TrackPoint[] = [];
    let idx = 0;
    while (container?.length) {

        // Ottengo l'indice della prossima tappa
        const { index } = nearestPlace(
          turfDistances(destinations(), position)
        );

        // Ricavo l'ordine della prossima tappa
        const orderOnMap = container.splice(index, 1)[0];

        // Ricavo i riferimenti agli altri ordini che hanno lo stesso punto di prelievo
        const refsPickup = orders.filter( ({order}) => { return ((order.pickup_lat.toString() + order.pickup_lon.toString() )== (orderOnMap.order.pickup_lat.toString() + orderOnMap.order.pickup_lon.toString())) &&  order.id != orderOnMap.order.id ;});
        // Ricavo i riferimenti agli altri ordini che hanno lo stesso punto di consegna
        const refsDropOff = orders.filter( ({order}) => { return ((order.dropoff_lat.toString() + order.dropoff_lon.toString()) == (orderOnMap.order.dropoff_lat.toString() + orderOnMap.order.dropoff_lon.toString())) &&  order.id != orderOnMap.order.id;});
        // Aggiungo i due punti dell'ordine alla lista
        points.push(
          { type: 'pickup',   orderOnMap: orderOnMap, ordinalNumber : ++idx, refs: refsPickup },
          { type: 'dropoff',  orderOnMap: orderOnMap, ordinalNumber : ++idx, refs: refsDropOff}
        );

        // Mi sposto nel punto di consegna
        const { order: {dropoff_lat, dropoff_lon} } = orderOnMap;
        position = [dropoff_lat, dropoff_lon];

    }

    // Ripristino l'ordine precedentemente salvato
    if ( this.existingPlan?.length && !this.readonly ) {
      points.sort(sortByPosition(this.existingPlan))
    }
    else if (this.existingPlan?.length && this.readonly) {
      points.sort(sortByIndex(this.existingPlan));
    }

    this.trackPoints = points;
    this.resortOrdinalNumber();
  }

  showRouteDetails: boolean = false;

  routeDetails: {
    distance: number,
    duration: number
  } = null;

  get distanceKm() {
    return `${(this.routeDetails?.distance/1e3).toFixed(2)}`
        + this.$t("common.km");
  }

 get duration() {
    const d = this.routeDetails?.duration;

    if (d) {
      const h = +(d/3600).toFixed();
      const m = +(+h >= 1 ? (d-(+h*3600))/60 : (d/60)).toFixed();

      return [
        (h > 0) ? `${h} ${this.$t('common.hours', h).capitalize()}` : '',
        (m > 0) ? `${m} ${this.$t('common.minutes', m).capitalize()}` : '',
      ].join(' ');
    }
  }

  displayResults() {
    this.showRouteDetails = true;
  }

  checkPosition(orderId: number) {
    const tps = this.trackPoints
      .filter(tp => tp.orderOnMap.orderId === orderId);

    return tps[0].type === 'pickup';
  }

  savePlan() {
    this.$waitFor( async () => {
      const plan = this.trackPoints
        .reduce( (a,x) => {
          const position = (a.last()?.position || 0) + 1;
          a.push({
            position,
            order_id: x.orderOnMap.orderId,
            step: x.type === 'pickup' ? 1 : 2
          });

          return a;
        }, [] as OrderStep[]);

      await planningService.save(this.paramsForRequest, plan);

      this.isDirty = false;
      this.$successMessage( "Pianificazione aggiornata" );
    }, "Salvataggio non riuscito")
  }

  private _loadTaker() {
    return this.$waitFor(async () => {
      this.taker = await takersService.getById(this.takerId, {
        date: moment(this.date).format("YYYY-MM-DD"),
        shift_id: this.shiftId || null,
        zone_id: this.zoneId
      });
    })
  }

  private _loadZone() {
    return this.$waitFor(async () => {
      this.zone = await zonesService.getById(this.zoneId);
    })
  }

  private async _init(){
    await this._loadTaker();

    this.takerOnMap  = new TakerOnMap(this.taker);
    this.takerOnMap.show();

    await this._loadZone();

    // Carico una possibile precedente configurazione
    this._loadExistingPlan();

    // Carico lista ordini assegnati al taker
    await this._loadOrders();

    // Carico la mappa
    this._buildMap(this.mapElement);

    // Disegno il punto di partenza del taker
    this.drawTakerOnMap();

    // Disegno gli ordini nella mappa
    this.drawOrdersOnMap();
  }

  async mounted() {
    // Mi assicuro che lo store abbia le fasce orarie in memoria
    this.$store.dispatch('loadShifts');

    this._init();
  }
}

export interface TrackPoint {
  type          : 'pickup' | 'dropoff';
  ordinalNumber : number;
  orderOnMap    : OrderOnMap;
  refs          : OrderOnMap[];
}
